@tailwind base;
@tailwind components;
.react-switch-bg > div {
  width: 80px !important;
}
.AnyClassForContainer {
  position: fixed;
  right: -100px;
  bottom: 50px;
  transition: right 0.5s;
  cursor: pointer;
  background-color: #3054a5;
  width: 50px;
  height: 50px;
  font-size: 20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  outline: none;
  z-index: 3000;
}

.AnyClassForTransition {
  right: 20px;
}
@tailwind utilities;
